<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/countryside-banner.jpg">
			<div class="words">
				<p>
					<span>数字乡村—危房管理云平台</span>
					软件定制化服务提供商
				</p>
			</div>
		</div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>数字乡村—危房管理云平台</span>
					<p class="crumbs"><a>您的位置：</a> <a>产品</a>> <a>数字乡村—危房管理云平台</a>
					</p>
				</div>
				<div class="content">
					<p class="indent">
						城镇房屋使用安全是公共安全的重要组成部分，与人民群众生活财产安全密切相关。2015年，贵州省、天津市相继发生了4起房屋垮塌事故，事故发生后，李克强总理、张高丽副总理相继做出重要指示，社会也高度关注。
						为提高危房管理自动化、信息化水平，<b>平台主要达到以下几个目的：</b>
					</p>
					<p>
						一、实现危房管理的数字化、网络化和标准化<br />
						二、实现危房排查、鉴定及巡查的信息化管理<br />
						三、建立群测群防与自动化监测机制实现危房动态管理<br />
						四、建立危房应急指挥系统，实现全市范围应急指挥
					</p>
					<p>
						<b>平台设计思路:</b>
					</p>
					<img src="@/assets/images/products/countryside/01.jpg" alt="平台设计思路">

					<p>
						<b>
							系统架构
						</b><br />
						基于地质灾害群测群防应急指挥中心，利用其强大的数据集中化及分析系统进行数据综合性分析。<br />
						建设基于危房的数据采集、巡查管理体系。<br />
						构建基于危房的自动化监测设备支撑体系及自动化监测管理系统<br />
						建设基于危房排查、危房鉴定及基础数据管理为核心的危房综合管理系统。
					</p>
					<img src="@/assets/images/products/countryside/02.png" alt="系统架构">
					<p>
						<b>
							信息化管理闭环
						</b><br />
						以专业的监测设备、终端APP、电子标签、GPS、Web系统等技术为载体，构建危房信息化管理体系，形成信息化管理闭环，对危房的查、鉴、巡、监及应急指挥等各项业务工作形成有效的管理

					</p>
				</div>
				<div class="productsChilder">
					<div class="productsChilder-title">解决方案</div>

					<div class="content">
						<p>
							<b>一、建立管理与数据的标准化</b><br />
							针对危房管理目标及现状，需要进行全面的管理体系建设和标准化管理，并根据危房数字化、网络化、信息化管理的要求进行管理体系和数据标准的建设。<br />
							1、危房调排查<br />
							调查资质、调查工作规范、信息化调查管理、调查责任管理等<br />
							2、危房鉴定<br />
							危房鉴定资质要求、危房鉴定工作标准数据库、危房鉴定工作信息化管理规范<br />
							3、危房巡查<br />
							危房群测群防巡查管理规范、危房街道/社区巡查管理规范、危房所有权人自查规范<br />
							4、危房监测<br />
							自动化监测建点标准、自动化监测方法使用标准
						</p>
						<p>
							<b>二、危房应急指挥平台</b><br />
							除此以外，还能够与现场应急车、单兵系统、卫星电话、现场无人机等进行实时数据连接<br />
							避免重复建设：共用市级指挥中心核心GIS系统、会议会商系统、会场硬件支持系统。<br />
							数据资源共享：与城市管理指挥中心专题专题数据库对接，实现数据互通，实时调用<br />
							一个城市一张图：通过市级指挥中心与国土内网连接，能够与地图系统实现接入，进而实现调用各类专题地图数据的目的
						</p>
						<p>
							<b>
								三、危房综合管理系统（1）
							</b><br />
							危房综合管理系统包含对危房基础数据的管理，危房调排查/危房鉴定的信息化管理。<br />
							危房调排查：基于手持移动终端的APP程序，利用GPS获取或核查危房位置信息，同时利用NFC技术创建电子标签，贴示在房屋明显处。通过APP可控制调排查责任人，丰富调排查内容，提高调排查数据真实性。<br />
							危房鉴定：由危房鉴定单位根据调查结果内容在Web平台端进行房屋的最终鉴定，并输出电子鉴定报告。<br />
						</p>
						<img src="@/assets/images/products/countryside/03.png" alt="危房综合管理系统">
						<p>
							<b>四、危房群测群防管理系统</b><br />
							危房群测群防排查：基于手持终端的APP，利用NFC电子打卡技术实现考核管理，在现场对房屋进行巡视检查，完成危房常规检查。针对社区街道、房屋所有权人等均可提供该巡查功能实现不同角色对危房的巡查需求。
						</p>
						<p>
							<b>五、危房自动化监测系统</b><br />
							危房自动化监测系统主要采集自动化监测数据，利用阈值对监测值的有效判断实现自动告警<br />
							自动告警：实现阈值是针对每个监测点配置的告警触发值，当监测临界值超过或达到该值时，系统自动触发告警。<br />
							设备状态管理：包含根据设备提供的实时“心跳”数据对设备的运行状态进行管理。<br />
							多数据分析：结合监测对方多种监测方法数据分析，结合当地气象雨量、地灾信息分析
						</p>
					</div>
				</div>
				<div class="productsChilder">
					<div class="productsChilder-title">技术创新</div>
					<div class="content">
						<h3><b>一、技术特点</b></h3>
						<img src="@/assets/images/products/countryside/04.png" alt="技术特点">
						<img src="@/assets/images/products/countryside/05.png" alt="技术特点">
						<h3> <b>二、方案特点</b></h3>
						<p>
							<b>1、信息化管理闭环</b>
							从危房调查、排查、评估、危房巡查、监测、应急指挥等实现了全流程管理。<br />
							<b>2、移动终端</b><br />
							a.基于移动智能手机群测群防终端能够实现对危房的日常巡查监测。<br />
							b.基于移动智能平板的调查终端能够在危房现场完成基本数据的排查、鉴定，并且保证了数据的真实性、完整性和统一性。<br />
							<b> 3、电子标签</b><br />
							NFC电子标签仅能够识别10CM范围内的电子识别，在室内条件下GPS定位失效，无法有效获取巡查人的物理位置，因此在特定巡查点通过NFC电子识别即可有效解决该问题。同时又对每个巡查点建立了电子档案并进行精细化管理。<br />
							<b>4、自动化监测</b><br />
							自动化监测是利用自动化监测设备对危房房屋结构进行持续监测的方法，能够有效对房屋的结构进行实时监测，并通过危房自动化监测系统进行数据分析和判断，并实现自动告警，大幅提高了监测的可靠性。<br />
							<b>5、多数据融合</b><br />
							本项目能够高效整合各地的地质灾害应急指挥平台，结合当地地质灾害的监测数据、雨量数据、气象数据，能够根据警示区、降雨量、地质灾害自动化监测结果进行综合数据展示和分析。
						</p>

						<h3><b>三、方案先进性</b></h3>
						<p>
							本系统从危房全流程监管出发，针对危房调查、评估、改造、巡查、监测等各项业务环境进行全业务流程的信息化监管，通过GPS、NFC、物联网通信技术等实现对户外作业的信息化管理，大幅提高了数据来源的可靠性和稳定性，并通过智能终端和自动化监测技术实现了对危房的长期监测。
						</p>
					</div>
				</div>
				
				<div class="productsChilder">
					<div class="productsChilder-title">浙江胤德信息科技有限公司</div>
					<div class="content">
						<p class="indent">
							浙江胤德信息科技有限公司是一家致力于医疗信息系统研发、实施和数字乡村建设的高科技企业。目前已经完成区域全民健康信息平台解决方案、智慧医疗共体服务平台建设与应用、智能化血液净化管理系统、数字乡村综合服务体系解决方案的研发，并在多个地区和医院完成部署实施，高质稳定的运行，等到业界至高评价。区域全民健康信息平台通过云部署实现了地区（省）医疗机构的互联互通、数据共享、远程指导等；智慧医共体服务平台建设亦是通过云部署实现了各医疗机构的互联互通、医疗资源的共享；智能化血液净化管理系统为血透患者的规律透析精准化治疗提供了强有力的数据支撑；数字乡村和服务体系解决方案在全面做好十大板块内容的基础上以数字技术与农村经济深度融合为主攻方向，以数据为关键生产要素，着力建设基础数据资源体系，加强数字生产能力建设，加快数字生产能力建设，加快农业农村生产经营，管理服务数字化改造，推动政府信息系统和公共数据互联开放共享，全面提升农业农村生产智能化、经营网络化、管理高效化、服务便捷化。
						</p>
					
					</div>
				</div>
				

			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"
export default ({
	components: {
		MenuLayout
	},
	methods: {

	}
});
</script>
